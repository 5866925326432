import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";
import Photo from "@components/photo";
import Phrase from "@components/phrase";
const Hero = ({ data = {} }) => {
  const { style, phrase, images } = data;
  const [dividerMaxWidth, setDividerMaxWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    // get the width of the words
    if (ref.current) {
      let containerWidth = ref.current.getBoundingClientRect().width,
        words = ref.current.querySelectorAll("div.px-gutter"),
        wordsWidth = 0;

      [...words].map((word, idx) => {
        wordsWidth += word.getBoundingClientRect().width;
      });
      setDividerMaxWidth(containerWidth - wordsWidth);
    }
  });
  return (
    <section
      className={cx(
        "hero",
        "px-gutter",
        "py-gutter",
        "pt-0",
        "max-h-screen",
        "h-[73vh]",
        "sm:h-[calc(100vh-73px-50px)]",
        "bg-gray",
        "relative",
        "z-20"
      )}
    >
      <div
        className={cx(
          "flex",
          "flex-col",
          "relative",
          "w-full",
          "h-full",
          "text-center"
        )}
      >
        {style == "default" && (
          <>
            <div className={cx("mt-10", "mb-6", "mx-auto", "w-full")}>
              <div className="hidden sm:block">
                {phrase && (
                  <Phrase
                    phrase={phrase}
                    dividerMaxWidth="50vw"
                    reverse={true}
                    delay="0.3"
                    typeStyle={
                      "text-jumbo-mobile md:text-jumbo whitespace-nowrap"
                    }
                  />
                )}
              </div>
              <div className="block sm:hidden">
                {phrase && (
                  <Phrase
                    hideLast={true}
                    dividerMaxWidth="100px"
                    vertical={true}
                    phrase={phrase}
                    typeStyle={"text-jumbo-mobile md:text-jumbo"}
                  />
                )}
              </div>
            </div>

            <div
              className={cx(
                "relative",
                "image-wrap",
                "h-full",
                "rounded-default",
                "overflow-hidden"
              )}
            >
              {images.map((image, idx) => (
                <Photo key={idx} photo={image} width={1600} layout="fill" />
              ))}
            </div>
          </>
        )}
        {style == "phraseOverlay" && phrase && (
          <>
            <div
              className={cx(
                "relative",
                "image-wrap",
                "h-full",
                "rounded-default",
                "overflow-hidden"
              )}
            >
              {phrase && (
                <div
                  className={cx(
                    "absolute",
                    "bottom-0",
                    "z-10",
                    "flex",
                    "justify-center",
                    "w-full",
                    "px-gutter",
                    "text-center"
                  )}
                >
                  {phrase && (
                    <>
                      <div
                        ref={ref}
                        className={cx(
                          "text-center",
                          "mb-5",
                          "hidden",
                          "md:block",
                          "w-full",
                          "absolute",
                          "bottom-0"
                        )}
                      >
                        {dividerMaxWidth && (
                          <Phrase
                            phrase={phrase}
                            color="#ffffff"
                            dividerMaxWidth={`${dividerMaxWidth}px`}
                            delay={0.4}
                            duration="3"
                            ease="easeInOut"
                            phraseMaxWidth={false}
                            typeStyle={
                              "text-jumbo-mobile md:text-jumbo text-white w-full"
                            }
                          />
                        )}
                      </div>
                      <div
                        className={cx(
                          "grid",
                          "text-center",
                          "mb-10",
                          "md:hidden"
                        )}
                      >
                        <Phrase
                          phrase={phrase}
                          vertical={true}
                          hideLast={true}
                          color="#ffffff"
                          dividerMaxWidth="100px"
                          typeStyle={
                            "text-jumbo-mobile-xs xs:text-jumbo-mobile md:text-jumbo text-white"
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}

              {images.map((image, idx) => (
                <Photo key={idx} photo={image} width={1200} layout="fill" />
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Hero;
